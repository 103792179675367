// assets
import { IconUser, IconBook, IconNotebook, IconCreditCard, IconReportAnalytics } from '@tabler/icons';

// constant
const icons = {
    IconUser,
    IconBook,
    IconNotebook,
    IconCreditCard,
    IconReportAnalytics
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const user_profile = {
    id: 'profile',
    title: 'Profile',
    type: 'group',
    admin: false,
    show: 'user',
    children: [
        {
            id: 'user-update-profile',
            title: '1. Fill Details',
            type: 'item',
            url: '/user/profile/update',
            icon: icons.IconUser,
            breadcrumbs: false
        },
        {
            id: 'user-register-course',
            title: '2. Apply for Course',
            type: 'item',
            url: '/user/course/register',
            icon: icons.IconBook,
            breadcrumbs: false
        },
        {
            id: 'user-course-payment',
            title: '3. Make Payment',
            type: 'item',
            url: '/user/course/payment',
            icon: icons.IconCreditCard,
            breadcrumbs: false
        },
        {
            id: 'user-list-course',
            title: '4. My Courses',
            type: 'item',
            url: '/user/course/list',
            icon: icons.IconNotebook,
            breadcrumbs: false
        },
        {
            id: 'user-list-results',
            title: '5. Exam',
            type: 'item',
            url: '/user/exam/list',
            icon: icons.IconReportAnalytics,
            breadcrumbs: false
        }
    ]
};

export default user_profile;
