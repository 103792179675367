// project imports
import config from 'config';

// action - state management
import * as actionTypes from './actions';

export const initialState = {
    user_name: 'NA',
    user_email: 'NA',
    auth_token: null,
    isAuthenticated: false,
    isAdmin: false
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const applicationStoreReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_USER_NAME:
            return {
                ...state,
                user_name: action.user_name
            };
        case actionTypes.SET_USER_EMAIL:
            return {
                ...state,
                user_email: action.user_email
            };
        case actionTypes.SET_IS_ADMIN:
            return {
                ...state,
                isAdmin: action.isAdmin
            };
        case actionTypes.SET_IS_AUTHENTICATED:
            return {
                ...state,
                isAuthenticated: action.isAuthenticated
            };
        case actionTypes.SET_TOKEN:
            return {
                ...state,
                auth_token: action.auth_token
            };
        default:
            return state;
    }
};

export default applicationStoreReducer;
