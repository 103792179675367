import { useRoutes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

// routes
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';
import MainLayout from 'layout/MainLayout';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

const UpdateProfilePage = Loadable(lazy(() => import('views/user/UpdateProfile')));
const RegisterCourse = Loadable(lazy(() => import('views/user/RegisterCourse')));
const UserCourseList = Loadable(lazy(() => import('views/user/UserCourseList')));
const CoursePayment = Loadable(lazy(() => import('views/user/CoursePayment')));

const UserListPage = Loadable(lazy(() => import('views/user/UserList')));
const UserPaymentsPage = Loadable(lazy(() => import('views/user/UserPayments')));

const CourseListPage = Loadable(lazy(() => import('views/courses/CourseList')));
const NewCoursePage = Loadable(lazy(() => import('views/courses/NewCourse')));
const CourseAdmitPage = Loadable(lazy(() => import('views/courses/CourseAdmit')));

const ExamResultsPage = Loadable(lazy(() => import('views/exam/ExamResultList')));

// login option 3 routing
const AuthLogin = Loadable(lazy(() => import('views/pages/authentication/auth/Login')));
const AuthRegister = Loadable(lazy(() => import('views/pages/authentication/auth/Register')));
const ForgotPassword = Loadable(lazy(() => import('views/pages/authentication/auth/ForgotPassword')));

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    const applstore = useSelector((state) => state.applstore);

    return useRoutes([
        {
            path: '/',
            element: applstore.isAuthenticated ? <MainLayout /> : <Navigate to="/pages/login" />,
            children: [
                {
                    path: '/',
                    element: applstore.isAdmin ? <DashboardDefault /> : <Navigate to="/user/course/list" />
                },
                {
                    path: '/dashboard/default',
                    element: applstore.isAdmin ? <DashboardDefault /> : <Navigate to="/user/course/list" />
                },
                {
                    path: '/user/profile/update',
                    element: <UpdateProfilePage />
                },
                {
                    path: '/admin/user/list',
                    element: <UserListPage />
                },
                {
                    path: '/admin/user/payments',
                    element: <UserPaymentsPage />
                },
                {
                    path: '/admin/course/list',
                    element: <CourseListPage />
                },
                {
                    path: '/admin/course/new',
                    element: <NewCoursePage />
                },
                {
                    path: '/user/course/register',
                    element: <RegisterCourse />
                },
                {
                    path: '/user/course/list',
                    element: <UserCourseList />
                },
                {
                    path: '/user/course/payment',
                    element: <CoursePayment />
                },
                {
                    path: '/admin/user/course/admit',
                    element: <CourseAdmitPage />
                },
                {
                    path: '/user/exam/list',
                    element: <ExamResultsPage />
                }
            ]
        },
        {
            path: '/',
            element: <MinimalLayout />,
            children: [
                {
                    path: '/pages/login',
                    element: <AuthLogin />
                },
                {
                    path: '/pages/register',
                    element: <AuthRegister />
                },
                {
                    path: '/forgotPassword',
                    element: <ForgotPassword />
                }
            ]
        }
    ]);
}
