// assets
import {
    IconUsers,
    IconList,
    IconBook,
    IconFilePlus,
    IconReportAnalytics,
    IconReportMedical,
    IconUserCheck,
    IconCreditCard
} from '@tabler/icons';

// constant
const icons = {
    IconBook,
    IconFilePlus,
    IconList,
    IconUsers,
    IconReportAnalytics,
    IconReportMedical,
    IconUserCheck,
    IconCreditCard
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const admin = {
    id: 'admin',
    title: 'Administrator',
    type: 'group',
    admin: true,
    show: 'admin',
    children: [
        {
            id: 'admin-user-list',
            title: 'User List',
            type: 'item',
            url: '/admin/user/list',
            icon: icons.IconUsers,
            breadcrumbs: false
        },
        {
            id: 'admin-user-payments',
            title: 'User Payments Report',
            type: 'item',
            url: '/admin/user/payments',
            icon: icons.IconCreditCard,
            breadcrumbs: false
        },
        {
            id: 'admin-course',
            title: 'Course',
            type: 'collapse',
            icon: icons.IconBook,
            children: [
                {
                    id: 'admin-course-list',
                    title: 'Course List',
                    type: 'item',
                    url: '/admin/course/list',
                    icon: icons.IconList,
                    breadcrumbs: false
                },
                {
                    id: 'admin-add-course',
                    title: 'Add New Course',
                    type: 'item',
                    url: '/admin/course/new',
                    icon: icons.IconFilePlus,
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'admin-result',
            title: 'Results',
            type: 'collapse',
            icon: icons.IconReportAnalytics,
            children: [
                {
                    id: 'admin-result-upload',
                    title: 'Upload Result',
                    type: 'item',
                    url: '/admin/results/list',
                    icon: icons.IconReportMedical,
                    breadcrumbs: false
                }
            ]
        }
    ]
};

export default admin;
